import { Button } from 'reactstrap'
import { navigate } from 'gatsby'
import React from 'react'
import SEO from '../components/SEO'
import Wrapper from '../components/Wrapper'
import { HOME } from '../data/constants'

const NotFoundPage = () => {
  const handleClick = () => {
    navigate(HOME)
  }
  return (
    <>
      <SEO page="oops404" />
      <Wrapper title="404 Not Found">
        <div className="col-sm-6 p-5">
          {/* <h2 className="text-light">Oops...!</h2> */}
          <h1 className="text-light page-not-found">404 - Page not found</h1>
          <p className="text-light">
            The page you are looking for might have been removed or had its name
            changed.
          </p>
          <Button
            color="danger"
            size="md"
            className="mt-2"
            onClick={handleClick}
          >
            Go to Homepage
          </Button>
        </div>
      </Wrapper>
    </>
  )
}

export default NotFoundPage
